import React from "react"
import { Box, Grid, Text, Container } from "@theme-ui/components"
import { GatsbyImage } from "gatsby-plugin-image"

const ImageAndText = ({
  title,
  body,
  images,
  subtitle,
}) => {
  
  return (
    <Box sx={{ py: [3,3,3,3], position: "relative" }}>
      <Container>
        <Grid columns={[1, "1fr 1fr"]} gap={[0,0,6,6]}>
          <Box>
            {images.length > 1 ? (
              <Grid
                sx={{
                  gridTemplateColumns: "repeat(7,1fr)",
                  gridTemplateRows: "repeat(4,1fr)",
                }}
              >
                {images.map((image,index) => {
                  return (
                    <Box
                      sx={{
                        gridColumn: index === 0 ? "1/5" : "3/8",
                        gridRow: index === 0 ? "1/3" : "2/8",
                        ".gatsby-image-wrapper": {},
                      }}
                    >
                      <GatsbyImage image={image.gatsbyImageData} alt="" />
                    </Box>
                  )
                })}
              </Grid>
            ) : (
              <>
                <Box
                  sx={{
                    p: [4],
                    ".gatsby-image-wrapper": {},
                  }}
                >
                  <GatsbyImage image={images.gatsbyImageData} alt="" />
                </Box>
              </>
            )}
          </Box>
          <Box sx={{ py: [2,2,3,7] }}>
            <Box>
              <Text variant="caption"> {subtitle}</Text>
            </Box>
            <Box>
              <Text
                as="div"
                variant="sectionTitle"
                sx={{
                  py: 3,
                  pb: 4,
                }}
                dangerouslySetInnerHTML={{ __html: title }}
              />
            </Box>
            <Box>
              <Text

                sx={{ p: [3,6,6,6], display:"block" , py:[3,3,3,3] }}
                variant="sectionTitle"
                dangerouslySetInnerHTML={{ __html: body }}
              />
            </Box>
          </Box>
        </Grid>
      </Container>
    </Box>
  )
}

export default ImageAndText
